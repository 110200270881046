<template>
  <Html class="h-screen w-screen overflow-hidden">
    <Body>
      <nuxt-layout>
        <nuxt-page />
      </nuxt-layout>

      <app-toast-notification-handler />
    </Body>
  </Html>
</template>

<script lang="ts" setup>
useSeoMeta({
  robots: "noindex",
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Pollen Learner` : "Pollen Learner";
  },
  ogImage: "https://heypollen.fr/images/brand/og-image.jpg",
});
</script>
