import en from "~/core/i18n/messages/en.json";
import fr from "~/core/i18n/messages/fr.json";

export default defineNuxtPlugin((nuxtApp) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  nuxtApp.$setLocaleMessages("fr", fr);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  nuxtApp.$setLocaleMessages("en", en);
});
