export default defineNuxtRouteMiddleware(async (to) => {
  const { isAuthenticated, me } = useCurrentUser();
  const { $analytics } = useNuxtApp();

  if (me.value && useState("identify_id").value !== me.value.id) {
    $analytics.identify(me.value.id, {
      email: me.value.email,
    });
    useState("identify_id").value = me.value.id;
  }

  if (isAuthenticated.value && to.name === "auth-login") {
    return await navigateTo((to.query.redirect as string) ?? "/");
  }

  if (to.meta.requiresAuthentication && !isAuthenticated.value) {
    return await navigateTo({
      name: "auth-login",
      query: {
        redirect: to.fullPath,
      },
    });
  }
});
